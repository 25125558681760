import { Box, Heading, SimpleGrid, VStack } from '@chakra-ui/react'
import Profile, { SOCIAL_TYPES } from '../../components/Profile'

const team = [
  {
    name: 'Colin Gagich',
    title: 'Co-Founder',
    bio: 'Serial Entrepreneur & robot sympathizer',
    img: '/content/team/colin.jpeg',
    socials: [
      { href: 'https://twitter.com/colingagich', type: SOCIAL_TYPES.twitter },
      { href: 'https://www.linkedin.com/in/colingagich/', type: SOCIAL_TYPES.linkedin },
      { href: 'https://github.com/gagichce', type: SOCIAL_TYPES.github },
    ],
  },
  {
    name: 'Ronald Chan',
    title: 'Co-Founder',
    bio: 'Cypherpunk, BBS Sysop',
    img: '/content/team/ron.jpeg',
    socials: [
      { href: 'https://twitter.com/Netquity', type: SOCIAL_TYPES.twitter },
      { href: 'https://www.linkedin.com/in/rschan/', type: SOCIAL_TYPES.linkedin },
    ],
  },
  {
    name: 'Hudson Graham',
    title: 'Software Engineer',
    bio: 'Coffee Addict & Car Enthusiast',
    img: '/content/team/hudson.jpeg',
    socials: [
      { href: 'https://twitter.com/hudsongraem_eth', type: SOCIAL_TYPES.twitter },
      { href: 'https://github.com/hudsongraeme', type: SOCIAL_TYPES.github },
    ],
  },
]

const advisors = [
  {
    name: 'Dr. David Harris Smith',
    title: 'Associate Professor',
    bio: 'Artificial Intelligence & Consciousness Researcher',
    img: '/content/team/dhs.jpeg',
    socials: [
      {
        href: 'https://www.researchgate.net/scientific-contributions/David-Harris-Smith-2112604173',
        type: SOCIAL_TYPES.rg,
      },
      { href: 'https://experts.mcmaster.ca/display/dhsmith', type: SOCIAL_TYPES.academic },
    ],
  },
  {
    name: 'Bede Schubert',
    title: 'Head of Venture at MHC Digital',
    bio: 'Deep Web3 Investment Experience Val. >$1bn USD',
    img: '/content/team/bede.jpeg',
    socials: [
      { href: 'https://twitter.com/0xb3d3', type: SOCIAL_TYPES.twitter },
      { href: 'https://www.linkedin.com/in/bedeschubert/', type: SOCIAL_TYPES.linkedin },
      {
        href: 'https://mhcdigitalgroup.com/team-member/bede-schubert/',
        type: SOCIAL_TYPES.academic,
      },
    ],
  },
]

const Base = ({ children, header = 'Meet our Leadership' }) => {
  return (
    <Box py={6} width={'full'}>
      <VStack width={'full'}>
        <Heading textColor={'white'}>{header}</Heading>
        <SimpleGrid width={'full'} columns={{ base: 1, sm: 2, md: 3 }} p="0" spacing={0}>
          {children}
        </SimpleGrid>
      </VStack>
    </Box>
  )
}

export const Team = ({ style }) => {
  return (
    <Base>
      {team.map((team) => (
        <Profile {...team} style={style} />
      ))}
    </Base>
  )
}

export const Advisors = ({ style }) => {
  return (
    <Base header="Our Advisors">
      {advisors.map((advisor) => (
        <Profile {...advisor} style={style} />
      ))}
    </Base>
  )
}
