import { Box, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { throttle } from 'lodash'
import { useState } from 'react'

const IridescentImage = ({ src, ...imageProps }) => {
  const [x, setX] = useState(0)
  const [hover, setHover] = useState(false)

  const handleMouse = throttle((event) => {
    setX(event.clientX - event.target.getBoundingClientRect().left)
  }, 100)

  return (
    <Box
      pos="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseMove={handleMouse}
    >
      <Image src={src} {...imageProps} />
      <Image
        as={motion.img}
        top={0}
        filter="saturate(400%) brightness(1.4)"
        pos="absolute"
        src={src}
        transition="opacity 1s ease-out"
        opacity={hover ? 1 : 0}
        __css={{
          WebkitMaskImage: `linear-gradient(90deg, rgba(0, 0, 0, 0) ${
            x - 200
          }px, rgba(0, 0, 0, 1) ${x}px, rgba(0, 0, 0, 0) ${x + 200}px)`,
        }}
        {...imageProps}
      />
    </Box>
  )
}
export default IridescentImage
