import { Text } from '@chakra-ui/react'
import Page from './Page'
import { HeaderSection } from '../components/Section'

const _bg = '#FF8E9E'

const NotFound = () => {
  return (
    <Page title="404" bg={_bg}>
      <HeaderSection
        style="white"
        bg={_bg}
        title="Whoops!"
        subtext="Our website shell-tered a turtle on this page and it can't seem to find its way out. We'll send in the rescue team ASAP!"
        img="/content/tortoise.png"
        href="/"
        isExternal={false}
        link_text={'Home →'}
      ></HeaderSection>
    </Page>
  )
}

export default NotFound
