import {
  Badge,
  Box,
  Button,
  FormControl,
  Input,
  FormLabel,
  Heading,
  CircularProgress,
  Textarea,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import bent from 'bent'
import formUrlEncoded from 'form-urlencoded'

const post = bent('https://inferencelabs.com/investor', 'POST', 200)

export const ContactUsSection = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError('')
    setSuccess('')
    setLoading(true)
    const body = {
      email,
      name,
      company,
      message,
    }
    const encoded = formUrlEncoded(body)
    try {
      const resp = await post('/contact', encoded, {
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      setSuccess('Received. We will be in touch shortly.')
    } catch (err) {
      console.log(err)
      setError('Error, please try again later!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box p={{ base: 2, md: 4 }} style={{ color: 'white' }} maxW={'xl'} margin={'auto'}>
      <form p="2" onSubmit={handleSubmit}>
        <Heading textAlign={'center'}>Contact Us</Heading>
        <Text textAlign={'center'}>Drop us a message and we'll get back to you shortly.</Text>
        <FormControl p="2" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            focusBorderColor="backgroundContrast"
            _placeholder={{ color: 'backgroundContrast' }}
            placeholder="Joe Smith"
            onChange={(event) => setName(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl p="2" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            focusBorderColor="backgroundContrast"
            _placeholder={{ color: 'backgroundContrast' }}
            placeholder="joe@me.com"
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl p="2">
          <FormLabel>Company</FormLabel>
          <Input
            type="text"
            focusBorderColor="backgroundContrast"
            _placeholder={{ color: 'backgroundContrast' }}
            placeholder="Inference Labs"
            onChange={(event) => setCompany(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl p="2">
          <FormLabel>Message</FormLabel>
          <Textarea
            placeholder="Hi, I'm interested in investing in Inference Labs!"
            focusBorderColor="backgroundContrast"
            _placeholder={{ color: 'backgroundContrast' }}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />
        </FormControl>
        <Button
          _hover={{
            transition: 'all .2s ease-in-out',
            bg: 'backgroundContrast',
          }}
          m="2"
          width={'fill'}
          variant="outline"
          type="submit"
        >
          {!loading ? 'Submit' : <CircularProgress isIndeterminate size="24px" />}
        </Button>
        {success && (
          <Badge m="2" fontSize="1em" colorScheme="green">
            {success}
          </Badge>
        )}
        {error && (
          <Badge m="2" fontSize="1em" colorScheme="red">
            {error}
          </Badge>
        )}
      </form>
    </Box>
  )
}
