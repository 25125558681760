import { Box } from '@chakra-ui/react'
import { DropdownSection } from '../../components/Dropdown'

const faqs = [
  {
    title: 'What is Inference Labs?',
    description:
      "Open, decentralized Artificial Intelligence (AI) has immense potential to benefit society as a whole. It is designed to be transparent, accessible, and inclusive, allowing for collaboration and innovation across various fields.  By building AI for the greater good, we can create solutions to some of the world's most pressing problems, such as climate change, poverty, and healthcare. Open Source AI also enables individuals and communities to have a say in the development of technology and ensures that the benefits of AI are distributed equitably. By embracing open, decentralized AI, we can build a better future for all.",
  },
  {
    title: 'What is Deep Learning?',
    description:
      'Deep learning is a subfield of Artificial Intelligence that involves training artificial neural networks to learn from large amounts of data and make predictions or decisions based on that learning.',
  },
  {
    title: 'What is a Neural Network?',
    description:
      'A neural network is a computing architecture inspired by biological neural networks, which serves as a foundation for many artificial intelligence systems. By processing information through interconnected nodes called neurons, it enables AI to learn, recognize patterns, and make data-driven decisions.',
  },
  {
    title: 'What is an Inference?',
    description:
      'In Artificial Intelligence, inference is the process of using a trained model (Neural Network) to draw conclusions or make predictions based on new data, which is a crucial component of many AI applications.',
  },
]

export const FAQSection = () => {
  return (
    <Box p={{ base: 2, md: 4 }} maxW={'4xl'} margin={'auto'}>
      <DropdownSection title="FAQs" items={faqs} />
    </Box>
  )
}
