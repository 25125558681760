import { Box } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'

const _base_title = '[inference labs]'

const Page = ({ header = true, footer = true, title, children, bg }) => {
  useEffect(() => {
    let _title = _base_title
    if (!isEmpty(title)) _title = `${title} - ${_base_title}`
    document.title = _title
  }, [])

  return (
    <Box p="0" w={'100%'} minH={'100vh'} bg={bg}>
      {header && <Header />}
      {children}
      {footer && <Footer />}
    </Box>
  )
}

export default Page
