import { Box } from '@chakra-ui/react'
import Page from './Page'
import { ContactUsSection } from '../sections/contact/contact'

const ContactPage = () => {
  return (
    <Page title="Contact">
      <Box pt={8}>
        <ContactUsSection />
      </Box>
    </Page>
  )
}

export default ContactPage
