import { Box } from '@chakra-ui/react'
import Page from './Page'
import { HeaderSection } from '../components/Section'
import { Advisors, Team } from '../sections/team/Team'

const _bg = '#ACB5AC'

const TeamPage = () => {
  return (
    <Page title="Team" bg={_bg}>
      <HeaderSection
        style="white"
        bg={_bg}
        title="A Seasoned Team of Entrepreneurs and Technologists"
        subtext="Combined 30+ years of startup experience"
        img="/content/bull.png"
        href="/"
        isExternal={false}
        link_text={"See what we're building →"}
      ></HeaderSection>
      <Box pt={8}>
        <Team style="light" />
        <Advisors style="light" />
      </Box>
    </Page>
  )
}

export default TeamPage
