import { Box, Center, Flex, Heading, Image, Link, Spacer, Stack, Text } from '@chakra-ui/react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import IridescentImage from '../components/IridescentImage'
import { HeaderSection, Section } from '../components/Section'
import { FAQSection } from '../sections/faq/FAQ'
import Page from './Page'

const Home = () => {
  const cheetahSectionRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: cheetahSectionRef,
    offset: ['start end', 'end end'],
  })
  const x = useTransform(scrollYProgress, (value) => value * 100 - 100, {
    clamp: false,
    ease: 'easeInOut',
  })

  return (
    <Page>
      <motion.div
        initial={{ filter: 'brightness(2)' }}
        whileInView={{ filter: 'brightness(1)' }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Flex
          p="10"
          my={{ base: '0', md: '32' }}
          alignContent={'center'}
          direction={{ base: 'column-reverse', md: 'row' }}
        >
          <Center p={'4'}>
            <Stack spacing="16px" textAlign={{ base: 'center', md: 'unset' }}>
              <Heading fontSize={{ base: 'xx-large', md: 'xxx-large' }} color={'white'}>
                Scaling of AI &&nbsp;web3
              </Heading>
              <Box
                w={'full'}
                h={'3px'}
                bgGradient="linear(to-l, #FCFCCD, #FCC6F5, #6BA5E0, #A0FFF6, #F9FFCD)"
              ></Box>
              <Heading fontSize={'large'} color={'white'} fontWeight={'light'}>
                Trustless Execution Layer of AI
              </Heading>
            </Stack>
          </Center>
          <Spacer />
          <Box mixBlendMode={'overlay'} px="48px">
            <Image
              mx={'auto'}
              minH={{ base: 'none', lg: 'xl' }}
              maxW={{ base: '100%', md: '400px', lg: '450px' }}
              src="/content/tree.webp"
            />
          </Box>
        </Flex>
      </motion.div>
      <Box p="10" color={'white'} fontWeight={'bold'} fontSize={'2xl'}>
        <Link href={'/contact'}>Contact Us →</Link>
      </Box>
      <Flex
        p="10"
        py={{ base: '1.7rem', md: '5rem' }}
        backgroundColor={'#524b59'}
        direction={{ base: 'column-reverse', lg: 'row' }}
        alignContent={'center'}
        ref={cheetahSectionRef}
      >
        <Center>
          <motion.div
            style={{
              opacity: scrollYProgress,
              x,
            }}
          >
            <Box px="24px">
              <IridescentImage
                opacity={'80%'}
                mx="auto"
                minH={{ base: 'none', lg: 'md' }}
                maxW={{ base: '100%', md: '450px', lg: '450px' }}
                src="/content/cheetah.png"
              />
            </Box>
          </motion.div>
        </Center>
        <Spacer />
        <Center px={'4'} py={'8'}>
          <Stack maxW={'800'}>
            <Text
              fontSize={{ base: 'x-large', md: 'xx-large' }}
              color={'white'}
              textAlign={{ base: 'center', lg: 'right' }}
              fontStyle={'italic'}
            >
              "Inference Labs specializes in developing advanced infrastructure and products for AI
              on web3. <br />
              Our lab focuses on AI inference interoperating on the blockchain, an essential step
              for building a future where anyone can access AI without counterparty risk. <br />
              We achieve this reality through the principles of unrestricted accessibility, privacy,
              and self-sovereignty."
            </Text>
          </Stack>
        </Center>
      </Flex>
      <Section
        bg="darkBg"
        style="light"
        subtext="Welcome to Inference Labs"
        title="Natural Selection"
        text={
          "Whether it's people talking to each other or machines communicating directly, the smooth and direct exchange of value cryptocurrency offers is the most natural and viable way to make artificial intelligence work together globally, regardless of borders."
        }
      >
        <IridescentImage p="8" src="/content/honeybee.png" />
      </Section>
      <Section
        bg="white"
        offset="center"
        style="dark"
        subtext="Why Choose Inference Labs"
        title="Zero Knowledge Technology"
        text={
          'Our zero-knowledge proofs ensure privacy and security without compromising on speed or efficiency. You can verify the correctness of off-chain AI output without having to calculate them yourself.'
        }
      >
        <IridescentImage p="8" src="/content/frog.png" />
      </Section>
      <Section
        bg="darkBg"
        offset="right"
        style="white"
        title="Trustless AI for a Decentralized Future"
        subtext="An Open-Source AI Inference Market"
        text={
          'Web3 brings transformative potential to the internet. Trustless neural network inferences provide the foundation for transparent, secure, and reliable interactions with AI, necessary for building a genuinely decentralized future.'
        }
      >
        <IridescentImage p="8" src="/content/butterfly.png" />
      </Section>
      <HeaderSection
        style="light"
        bg="#ACB5AC"
        title="Join the pack"
        img="/content/wolf.png"
        subtext="AI has a special relationship with web3. AI operating on open-source principles can make powerful, efficient decisions in an impartial and tamper-proof environment. We aim to make universal, accessible, and generalized products for AI to operate worldwide with web3."
        href="/about"
        isExternal={false}
        link_text={'Join us →'}
      />
      <FAQSection />
    </Page>
  )
}

export default Home
