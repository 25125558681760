import { Avatar, Box, Center, Link, Stack, Text } from '@chakra-ui/react'
import {
  FaGithub,
  FaLinkedin,
  FaQuestion,
  FaResearchgate,
  FaScroll,
  FaTwitter,
} from 'react-icons/fa'
import { Fade } from 'react-reveal'

export const SOCIAL_TYPES = {
  twitter: 'twitter',
  linkedin: 'linkedin',
  github: 'github',
  academic: 'academic',
  rg: 'rg',
}

const linkForSocial = (social) => {
  const { href, type } = social
  let icon
  switch (type) {
    case SOCIAL_TYPES.twitter:
      icon = <FaTwitter />
      break
    case SOCIAL_TYPES.linkedin:
      icon = <FaLinkedin />
      break
    case SOCIAL_TYPES.github:
      icon = <FaGithub />
      break
    case SOCIAL_TYPES.rg:
      icon = <FaResearchgate />
      break
    case SOCIAL_TYPES.academic:
      icon = <FaScroll />
      break
    default:
      icon = <FaQuestion />
  }

  return (
    <Link key={href} href={href} isExternal>
      {icon}
    </Link>
  )
}

const Profile = ({ img, name, title, bio, socials = [], style = 'dark' }) => {
  const textColor = style === 'dark' ? 'black' : 'white'
  const subTextColor = style === 'dark' ? 'gray.500' : 'gray.300'
  return (
    <Center>
      <Fade left cascade>
        <Stack
          maxWidth={'sm'}
          spacing={0}
          direction={'column'}
          textColor={textColor}
          p={6}
          textAlign={'center'}
        >
          <Box p={4}>
            <Avatar
              bgGradient="linear(45deg, #FCFCCD, #FCC6F5, #6BA5E0, #A0FFF6, #F9FFCD)"
              p={'3px'}
              src={img}
              size={'2xl'}
            />
          </Box>
          <Text fontSize={'lg'}>{name}</Text>
          <Text fontSize={'sm'} textColor={subTextColor}>
            {title}
          </Text>
          <Text py={2}>{bio}</Text>
          <Center py={2}>
            <Stack direction={'row'}>{socials.map(linkForSocial)}</Stack>
          </Center>
        </Stack>
      </Fade>
    </Center>
  )
}

export default Profile
