import {
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Center,
} from '@chakra-ui/react'

const DropdownItem = ({ title, description }) => {
  return (
    <AccordionItem key={title}>
      <AccordionButton>
        <Box fontSize={'2xl'} p={2} as="span" flex="1" textAlign="left">
          {title}
        </Box>{' '}
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>{description}</AccordionPanel>
    </AccordionItem>
  )
}

export const DropdownSection = ({ title, items = [], style = 'white' }) => {
  const textColor = style === 'dark' ? 'black' : 'white'
  return (
    <Box textColor={textColor} p={4}>
      <Center p={4}>
        <Heading>{title}</Heading>
      </Center>
      <Accordion allowToggle border={'none'}>
        {items.map((item) => DropdownItem(item))}
      </Accordion>
    </Box>
  )
}
