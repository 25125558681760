import { Box, Center, Heading, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { isEmpty } from 'lodash'
import { useRef } from 'react'
import { Slide } from 'react-reveal'
import Divider from './Divider'
import IridescentImage from './IridescentImage'

export const HeaderSection = ({
  title,
  subtext,
  bg,
  style = 'dark',
  img,
  href,
  link_text,
  isExternal = true,
}) => {
  const textColor = style === 'dark' ? 'black' : 'white'
  const maxWidth = { base: '100%', md: '50%', lg: '50%' }
  const _hover = isEmpty(href)
    ? {}
    : {
        transition: 'all .2s ease-in-out',
        filter: 'brightness(0.95)',
        cursor: 'pointer',
      }

  const ref = useRef(null)
  const { scrollYProgress } = useScroll({ target: ref, offset: ['start end', 'end end'] })
  const x = useTransform(scrollYProgress, (value) => value * -200 + 200, {
    clamp: false,
    ease: 'easeInOut',
  })

  return (
    <Box
      overflow="hidden"
      ref={ref}
      p={{ base: 6, md: 12 }}
      textColor={textColor}
      paddingTop={{ sm: '1', md: '3rem', lg: '3rem' }}
      bg={bg}
      _hover={_hover}
      onClick={() => {
        if (!isEmpty(href)) {
          isExternal ? window.open(href, '_blank', 'noopener,noreferrer') : (window.location = href)
        }
      }}
    >
      <Box>
        <Stack direction={{ base: 'column', md: 'row' }} paddingTop={{ base: '6', md: '3rem' }}>
          <Stack direction="column" maxWidth={maxWidth} spacing={'6'}>
            <Spacer />
            <Slide left cascade duration={500}>
              <Heading fontSize={{ base: '3xl', sm: '6xl', md: '5xl', lg: '6xl' }}>{title}</Heading>
              <Divider bg={textColor}></Divider>
              <Text fontSize={'large'}>{subtext}</Text>
            </Slide>
            <Spacer />
          </Stack>
          <Spacer />
          <Center>
            <Box
              as={motion.div}
              style={{ x, opacity: scrollYProgress }}
              py={{ base: '0.5rem', md: 'unset' }}
              maxWidth={{ base: 'full', md: '30rem' }}
            >
              <IridescentImage src={img} />
            </Box>
          </Center>
        </Stack>
        <Box paddingTop={{ base: '1rem', md: '3rem', lg: '10rem' }}>
          {!isEmpty(link_text) && (
            <Text fontWeight={'bold'} fontSize={'2xl'}>
              {link_text}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const Section = ({
  title,
  subtext,
  text,
  bg = 'white',
  style = 'dark',
  offset = 'left',
  children,
}) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  })
  const y = useTransform(scrollYProgress, [0, 1], [0, -20], { clamp: false })
  const y2 = useTransform(scrollYProgress, (y) => y * -200 + 200, {
    clamp: false,
  })
  const o = useTransform(scrollYProgress, [0, 1], [0, 1], { clamp: false })
  const textColor = style === 'dark' ? 'black' : 'white'
  let marginLeft = ''
  let width = { base: 'full', md: '60%', lg: '45%' }
  let columns = 1
  if (offset === 'center') marginLeft = { base: '0', md: '20%', lg: '27.5%' }
  else if (offset === 'right') marginLeft = { base: '0', md: '40%', lg: '55%' }
  else if (offset === 'full') {
    width = 'full'
    columns = { base: 1, md: 2 }
  }

  return (
    <Box p={12} bg={bg} ref={ref}>
      <Box textColor={textColor} marginLeft={marginLeft} width={width}>
        <Divider bg={textColor} />
        <Stack paddingTop={'2'} spacing={'4'}>
          <Text paddingBottom={'8'} fontWeight={'bold'} textTransform={'uppercase'}>
            {subtext}
          </Text>
          <motion.div style={{ y: y, opacity: o }}>
            <SimpleGrid columns={columns}>
              <Heading paddingBottom={'2'} maxWidth={'75%'}>
                {title}
              </Heading>
              <Text fontSize={'large'}>{text}</Text>
            </SimpleGrid>
          </motion.div>
          <motion.div style={{ y: y2, opacity: o }}>{children}</motion.div>
        </Stack>
      </Box>
    </Box>
  )
}
