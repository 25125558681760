import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import * as React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { About, Contact, Home, NotFound, Team } from './routes'

const theme = extendTheme({
  fonts: {
    body: 'Ubuntu,Helvetica,Arial,sans-serif',
    heading: 'Ubuntu,Helvetica,Arial,sans-serif',
  },
  colors: {
    primary: '#535252',
    secondary: '#ED7D31',
    darkBg: '#414141',
    tertiary: { 500: '#735966', 400: '#7f6270' },
    indigo: { 400: '#8a84e2', 500: '#726adc' },
    background: '#949db7',
    backgroundContrast: '#5A6587',
  },
  styles: {
    global: {
      body: {
        bg: '#949db7',
      },
    },
  },
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'about',
    element: <About />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'team',
    element: <Team />,
  },
  {
    path: 'not-found',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" />,
  },
])

ReactDOMClient.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
)
