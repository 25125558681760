import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Center, Flex, Image, Link, Spacer } from '@chakra-ui/react'
import { go } from '../util'

const Header = () => {
  return (
    <Flex p="4" w={'100%'} direction={{ base: 'column', sm: 'row' }}>
      <Image
        minH={20}
        p="1"
        src="/logo.png"
        w={{ base: 'full', sm: '250px' }}
        onClick={go.home}
        _hover={{ cursor: 'pointer' }}
      ></Image>
      <Spacer />
      <Center>
        <Link
          display={{ base: 'none', sm: 'block' }}
          color="white"
          p="4"
          href="https://docs.inferencelabs.com/litepaper"
          isExternal
        >
          litepaper <ExternalLinkIcon mx="2px" />
        </Link>
      </Center>
    </Flex>
  )
}

export default Header
